<template>
  <div class="index_box">
    <div class="created_app">
      <div class="title">
        <h4>
          {{ $t("createdApp") }}
        </h4>
        <p>
          {{ $t("openTitle") }}
        </p>
      </div>
      <div class="table_box">
        <div class="table_title">
          {{ $t("AppInfo") }}
          <s></s>
          <u></u>
        </div>
        <div class="table_input">
          <span>
            {{ $t("appName") }}
          </span>
          <input type="text" v-model="appInfo.appName" :disabled="isCompile" />
        </div>
        <div class="table_input">
          <span>
            {{ $t("appUrl") }}
          </span>
          <input type="text" v-model="appInfo.appUrl" />
        </div>
        <div class="table_img" v-if="false">
          <span>
            {{ $t("appIcon") }}
          </span>
          <div class="avatars">
            <div v-if="appInfo.appIcon" class="img_content">
              <img :src="appInfo.appIcon" alt="" />
            </div>
            <el-upload
              class="avatar-uploader"
              :before-upload="beforeAvatarUpload"
              :show-file-list="false"
              :auto-upload="false"
              action="#"
              v-else
              :on-change="changeUpload"
            >
              <div class="add_iconfont">
                <i class="iconfont icon-jia"></i>
              </div>
            </el-upload>
          </div>
        </div>
        <div class="table_input">
          <span>
            {{ $t("appMessage") }}
          </span>
          <textarea v-model="appInfo.appMessage"></textarea>
        </div>
        <div class="table_text">
          <span>
            {{ $t("receviceAddress") }}
          </span>
          <input type="text" v-model="appInfo.address" />
        </div>
        <div class="table_text">
          <span>
            {{ $t("userCode") }}
          </span>
          <div class="text">
            <p>
              {{ appInfo.userCode }}
            </p>
            <s>
              {{ $t("userCodeText") }}
            </s>
          </div>
        </div>
        <div class="table_text" v-if="clientId">
          <span>
            {{ $t("clientId") }}
          </span>
          <div class="text">
            <p>
              {{ clientId }}
            </p>
            <s> </s>
          </div>
        </div>
        <div class="table_text" v-if="secret">
          <span>
            {{ $t("secret") }}
          </span>
          <div class="text">
            <p>
              {{ secret }}
            </p>
            <s> </s>
          </div>
        </div>
        <div class="table_input">
          <span>
            {{ $t("oauthUrl") }}
          </span>
          <input type="text" v-model="appInfo.oauthUrl" />
        </div>
        <div class="table_button">
          <button @click="gotoUrl('open')" class="dark">
            {{ $t("cancel") }}
          </button>
          <button
            @click="debounce(subAppInfo, 1500, true)"
            v-loading="subloading"
          >
            {{ $t("submit") }}
          </button>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('viewonappcenter')"
            placement="bottom"
          >
            <button @click="subAppInfoToAppCenter" v-loading="subloading">
              {{ $t("subtoappcenter") }}
            </button>
            <!-- <p>{{$t("viewonappcenter")}}</p> -->
          </el-tooltip>
        </div>
      </div>
    </div>
    <el-dialog class="user_home" :visible.sync="isDialog" @close="handleClose">
      <span>{{ $t("openDialogTitle") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isDialog = false">{{ $t("cancel") }}</el-button>
        <el-button type="primary" @click="debounce(subAppInfo, 1500, true)">
          <!-- 升级 -->
          {{ $t("determine") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addProject, updateProject } from "@/api/api";
export default {
  data() {
    return {
      appInfo: {
        appName: "",
        appUrl: "",
        appIcon: "",
        appMessage: "",
        userCode: "",
        oauthUrl: "",
        address: "",
      },
      environmentType: undefined,
      secret: "",
      clientId: "",
      appStatus: 0,
      isDialog: false,
      isToCenter: false, //是否上架至应用广场
      subloading: false,
      isCompile: false,
      imageUrl: "",
      userInfo: {},
    };
  },
  created() {
    this.userInfo = this.$store.state.userData;
    if (localStorage.getItem("appItemInfo")) {
      const appItemInfo = JSON.parse(localStorage.getItem("appItemInfo"));
      console.log(appItemInfo);
      for (const key in this.appInfo) {
        this.appInfo[key] = appItemInfo[key];
      }
      this.appStatus = Number(appItemInfo.status);
      this.secret = appItemInfo.secret;
      this.clientId = appItemInfo.clientId;
      this.isCompile = true;
    } else {
      this.appInfo.userCode =
        Math.random().toString(36).substr(2) +
        Math.random().toString(36).substr(2);
    }
  },
  computed: {
    getUserInfo() {
      return this.$store.state.userData;
    },
    inspectAppInfo() {
      let isNoKey = "";
      for (const key in this.appInfo) {
        if (!this.appInfo[key]) {
          isNoKey = key;
        }
      }
      if (isNoKey && isNoKey !== "appIcon") {
        return isNoKey;
      } else {
        return false;
      }
    },
  },
  methods: {
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error(this.$t("uploadingExplain"));
        return false;
      }
      return isLt5M;
    },

    changeUpload(file, fileList) {
      console.log(file);
      var reader = new FileReader();
      // var _this = this
      reader.onload = (e) => {};
      reader.readAsDataURL(file.raw);
    },
    subAppInfoToAppCenter() {
      this.isToCenter = true;
      this.subAppInfo();
    },
    subAppInfo() {
      console.log(this.inspectAppInfo);
      if (this.appStatus === 3 && !this.isDialog) {
        this.isDialog = true;
        return;
      } else {
        this.isDialog = false;
      }
      if (this.inspectAppInfo) {
        return this.$message.error(
          this.$t("pleaseInput") + this.$t(this.inspectAppInfo)
        );
      }
      this.subloading = true;
      const userInfo = this.$store.state.userData;
      let params = {
        userType: userInfo.register,
        metaId: userInfo.metaId,
      };
      if (this.isToCenter) {
        params.environmentType = "Market";
        params.developerMetaId = userInfo.metaId;
      }
      params[userInfo.register] =
        userInfo.register === "phone" ? userInfo.phone : userInfo.email;
      if (!this.appInfo.appIcon)
        this.appInfo.appIcon = "https://s1.ax1x.com/2020/09/22/wLgu5D.png";
      Object.assign(params, this.appInfo);
      console.log(params);
      if (this.isCompile) {
        this.setUpdateProject(params);
      } else {
        this.setAddProject(params);
      }
      this.isToCenter = false;
    },

    setUpdateProject(params) {
      updateProject(params)
        .then(() => {
          this.subloading = false;
          this.$message({
            message: this.$t("submitSuccess"),
            type: "success",
          });
          this.gotoUrl("open");
        })
        .catch((err) => {
          this.subloading = false;
          return this.$message.error(this.$t("Error") + err.message);
        });
    },

    setAddProject(params) {
      addProject(params)
        .then(() => {
          this.subloading = false;
          this.$message({
            message: this.$t("submitSuccess"),
            type: "success",
          });
          this.gotoUrl("open");
        })
        .catch((err) => {
          this.subloading = false;
          return this.$message.error(this.$t("Error") + err.message);
        });
    },
    handleClose() {
      this.isToCenter = false;
    },
  },
};
</script>

<style lang="scss">
.created_app {
  max-width: 1080px;
  margin: 0 auto;
  width: 100%;
  margin-top: 66px;
  .title {
    text-align: center;
    h4 {
      font-size: 3rem;
      color: #fff;
    }
    p {
      font-size: 14px;
      color: #99979a;
    }
  }
  .table_box {
    border-radius: 30px;
    background: #fff;
    max-width: 906px;
    margin: 0 auto;
    width: 100%;
    padding: 80px 150px 120px;
    margin-top: 45px;
  }
  .table_title {
    font-size: 2.4rem;
    position: relative;
    margin-bottom: 45px;
    s,
    u {
      position: absolute;
      left: -38px;
      width: 18px;
      height: 18px;
      top: 10px;
      background: #eab300;
    }
    u {
      left: -42px;
      top: 14px;
      opacity: 0.6;
    }
  }
  .table_input,
  .table_img,
  .table_text {
    margin-bottom: 45px;
    span {
      font-size: 1.6rem;
      color: #303133;
    }
    img {
      width: 90px;
      object-fit: contain;
      height: 90px;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
      max-width: 400px;
      width: 100%;
      border: 1px solid #bfc2cc;
      border-radius: 6px;
      height: 44px;
      line-height: 44px;
      padding-left: 10px;
      outline: none;
    }
    textarea {
      max-width: 400px;
      width: 100%;
      border: 1px solid #bfc2cc;
      border-radius: 6px;
      min-height: 48px;
      padding-top: 15px;
      line-height: 1.5;
      padding-left: 10px;
      border: 1px solid #bfc2cc;
    }
  }
  .table_img,
  .table_text {
    align-items: flex-start;
    p {
      font-size: 14px;
      text-align: left;
    }
    s {
      color: #909399;
      font-size: 14px;
    }
  }
  .avatars,
  .text {
    max-width: 400px;
    width: 100%;
    .add_iconfont {
      width: 90px;
      height: 90px;
      margin-bottom: 18px;
      line-height: 90px;
      background: #e7e8e9;
      border-radius: 12px;
      text-align: center;
    }
    .icon-jia {
      font-size: 24px;
      color: #909399;
    }
    img {
      height: 90px;
      width: 90px;
      object-fit: contain;
    }
  }
  .img_content {
    position: relative;
  }
  .table_button {
    display: flex;
    justify-content: space-around;
    margin-top: 80px;
    button {
      width: 150px;
      height: 46px;
      line-height: 46px;
      color: #fff;
      border-radius: 90px;
      cursor: pointer;
      background: #eab303;
      &:hover {
        background: #ecbb1a;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .table_box {
      padding: 20px;
    }
    .table_input,
    .table_img,
    .table_text {
      input,
      textarea,
      .text {
        max-width: 60%;
      }
    }
  }
}
.dark {
  background: #a2a6b2 !important;
  &:hover {
    background: #bfc2cc !important;
    transition: all 0.2s ease-in-out !important;
  }
}
.puton {
  p {
    margin-top: 10px;
    color: #909399;
  }
}
.user_home {
  ::v-deep .el-dialog {
    max-width: 600px;
    border-radius: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .user_home {
    ::v-deep .el-dialog {
      border-radius: 15px;
      width: 80%;
    }
  }
}
</style>
